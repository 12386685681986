// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import {getFirestore, collection} from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCoCSAxpBlcu81_zplb8-YLVEzDKT0tLm8",
  authDomain: "betaquants-blogs.firebaseapp.com",
  projectId: "betaquants-blogs",
  storageBucket: "betaquants-blogs.appspot.com",
  messagingSenderId: "94065093191",
  appId: "1:94065093191:web:e062de19fa5c100f53df62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth()
export const db = getFirestore(app); 
export const blogsref = collection(db, "blogs");
export const usersRef= collection(db, "users");
export const enquiryRef= collection(db, "enquiries");
export const newsletter_subcriber = collection(db, 'Newsletter Subcribers');
export const consultationRef = collection(db, 'Consulatation and Callback');
export const setMeetingRef = collection(db, 'Meeting Setup');
export const masterClassEnrol = collection(db, 'Enroll Master Class');
export const bannerRef = collection(db, 'banner');

export default app;