import React, { useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tpao25j",
        "template_jgh3fil",
        form.current,
        "k_Hfg6qNS5plTOlwO"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error);
        }
      );

    e.target.reset();
    alert("Email has been sent");
  };

  return (
    <div>
      <footer className="" style={{ background: "#272828" }}>
        <div className="container mx-auto w-full  p-4 sm:p-6">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="/" className="flex items-center">
                <img
                  style={{ height: "10rem" }}
                  src="betaquant-logo.png"
                  className=" mr-3"
                  alt="FlowBite Logo"
                />
              </a>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 ">
              <div>
                <h2 className="mb-6 text-md font-semibold uppercase text-white">
                  COMPANY
                </h2>
                <ul className="text-white text-base ">
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/aboutus"
                      >
                        About Us
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/courses"
                      >
                        Courses
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/softwares"
                      >
                        Softwares
                      </Link>
                    </a>
                  </li>

                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/tnc"
                      >
                        Terms & Conditions
                      </Link>
                    </a>
                  </li>

                  {/* <li>
                                        <a href="https://gauravkumarquant.herokuapp.com/Trade_simulator" className="hover:underline hover:text-purple-400">
                                            Market Tools
                                        </a>
                                    </li> */}
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-md font-semibold  uppercase text-white">
                  PLATFORM
                </h2>
                <ul className="text-white text-base">
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/blogpage2"
                      >
                        Blog
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/bqforum"
                      >
                        Discussion Forum
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a>
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/faq"
                      >
                        FAQs
                      </Link>
                    </a>
                  </li>

                  <li>
                    <a href="/contact">
                      <Link
                        className="hover:underline hover:text-gray-400"
                        to="/contact"
                      >
                        Contact-us
                      </Link>
                    </a>
                  </li>
                  {/* <li>
                                        <a
                                            href=""
                                            className="hover:underline hover:text-purple-400"
                                        >
                                            Path to Quant
                                        </a>
                                    </li> */}
                </ul>
              </div>
              <div className="items-center">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Enter your Name"
                      className="form-control placeholder-gray-400"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>

                  <div className="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      name="user_email"
                      placeholder="Enter your Email"
                      className="form-control placeholder-gray-400"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>

                  <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                    <div class=" bg-white rounded-t-lg dark:bg-gray-800">
                      <label for="comment" class="sr-only">
                        Your comment
                      </label>
                      <textarea
                        id="comment"
                        rows="4"
                        name="message"
                        class="w-full px-1 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="How can we help you?"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <input
                    type="submit"
                    value="Send"
                    class="inline-flex items-center p-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                  />
                </form>
              </div>
            </div>
          </div>
          <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-4" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-white sm:text-center ">
              © 2022 , All Rights Reserved{" "}
              <a href="/" className="hover:underline hover:text-purple-400">
                BETAQUANTS
              </a>
              .
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              <a
                href="https://www.youtube.com/channel/UCP6lDyyjBQnAxrYwbutiIiw"
                target={"_blank"}
                className="text-white "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-youtube"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />{" "}
                </svg>
                <span className="sr-only">Youtube</span>
              </a>

              <a
                href="https://medium.com/@betaquants.in"
                target={"_blank"}
                className="text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-medium"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z" />
                </svg>
                <span className="sr-only">Medium Page</span>
              </a>

              <a
                href="https://twitter.com/quants_beta"
                target={"_blank"}
                className="text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
              <a
                href="https://www.linkedin.com/company/betaquants-pvt-ltd"
                target={"_blank"}
                className="text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />{" "}
                </svg>
                <span className="sr-only">Linkedin page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
