import React from 'react';

const NotFound = () => {
    return (
        <div style={{ minHeight: `calc(100vh - 12rem)` }} className='flex items-center justify-center flex-col'>
            <h1 className='font-extrabold pb-2 text-3xl'>404 - Page Not Found</h1>
            <p className='font-medium'>The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFound;
